<template>
  <img
      v-if="!_IsEmpty(imgUrl)"
      :src="imgUrl"
      :srcset="img?.srcSet"
      :loading="lazy ? 'lazy' : 'eager'"
      :class="classList"
      :width="img?.mediaDetails?.width"
      :height="img?.mediaDetails?.height"
      :alt="img?.altText"
  />
</template>
<script setup lang="ts">
const props = defineProps({
  img: Object as PropType<ImgType>,
  setWebp: {
    required: false,
    type: Boolean,
    default: false,
  },
  lazy: {
    required: false,
    type: Boolean,
    default: false,
  },
  classList: {
    required: false,
    type: String,
    default: "mx-auto",
  },
});
const imgUrl = computed(() => {
  return (props?.img?.srcSet || props.setWebp) ? `${props?.img?.sourceUrl}.webp` : props?.img?.sourceUrl;
});

</script>
